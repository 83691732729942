<template>
  <div>
    <full-page-loader :loading="isLoading"/>
    <section-layout v-if="!isLoading">
      <div class="flex-center mb-3 text-boulder text-black text-lg font-niceone-bold hidden sm:flex" v-if="title" >{{
          data?.title
        }}
      </div>
      
    <template v-if="!isLoading">
      <div class="flex-center mb-3 text-boulder">{{ $t('pages.last_update', {last_updated: data?.last_updated}) }}</div>
      <div v-html="data?.description"></div>
    </template>
  </section-layout>
  </div>

</template>

<script setup>
import {privacyQuery} from "@/plugins/API/content";

const {isLoading, data} = privacyQuery()

defineProps({
  title: {
    type: Boolean,
  }
})
</script>