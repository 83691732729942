<template>
  <div
      class="hidden bg-white ltr:left-0 rtl:right-0 custom-container group-hover:flex top-14 transition ease-in-out delay-[.3s] shadow-cus absolute z-10">
    <div class="grid grid-cols-12 min-h-[450px] max-h-auto w-full">
      <div class="col-span-12 py-3 md:col-span-7">
        <empty-items :emptySubCategories="emptySubCategories"/>
        <sub-items :subCategories="subCategories"/>
      </div>
      <menu-types :selected-slug="selectedSlug"/>
    </div>
  </div>
</template>

<script setup>
import {useCategoryStore} from "@/store/category";
import SubItems from "@/components/common/menuitems/SubItems";
import EmptyItems from "@/components/common/menuitems/EmptyItems";
import MenuTypes from "@/components/common/dynamic-menu-components/index";

const props = defineProps({
  index: {
    Type: Number,
    default: 0
  }
});

const categoryStore = useCategoryStore();
const {getCategories} = storeToRefs(categoryStore)

const selectedCategory = computed(() => getCategories?.value[props.index] || [])
const subCategories = computed(() => selectedCategory?.value?.categories.filter(item => item.categories.length > 0))
const emptySubCategories = computed(() => selectedCategory?.value?.categories.filter(item => item.categories.length === 0))
const selectedSlug = ref(getCategories?.value[props.index] ? getCategories?.value[props.index].seo_name : '');
</script>