<template>
  <div class="flex flex-col gap-3 w-full mb-2`">
    <nuxt-link v-for="(item ,index) in getBanners" :key="index" :to="$routerUrl(item.seo_url)">
      <niceone-image :src="$publicPath(item.image)" class="rounded-md w-full"/>
    </nuxt-link>
  </div>
</template>

<script setup>
const props = defineProps({
  data: {
    type: Object,
    default: () => {
    }
  },
  banners: {
    type: Array,
    default: () => []
  }
})
const getBanners = computed(() => props.banners?.length ? props.banners : props.data.banners)
</script>

<style lang="scss" scoped>
</style>