<template>
  <div
      class="flex items-center gap-3 h-[48px] text-black text-[16px] group-hover:border-b-secondary border-b-[3.7px] border-white">
    <van-icon name="wap-nav" size="24"/>
    <div>{{ $t('header.category.all_categories') }}</div>
    <van-icon name="arrow-down"/>
  </div>
  <div
      class="hidden bg-white ltr:left-0 rtl:right-0 custom-container group-hover:flex top-14 transition ease-in-out delay-[.3s] shadow-cus absolute z-10">
    <div class="grid grid-cols-12 min-h-[426px] max-h-auto w-full">
      <div class="col-span-12 lg:col-span-2 bg-[#f9f9f9] flex flex-col pt-2">
        <nuxt-link
            v-for="(category,index) in getCategories"
            :key="index"
            :to="$routerUrl(category.seo_url)"
            class="hover:bg-[#f4f4f4] flex items-center justify-between py-3 px-4"
            @click="onMenuClick(category)"
            @mouseover="hoverMainCategory(index,category)"
        >
          <div class="flex items-center gap-5">
            <niceone-image :alt="category.name" :class="{ 'opacity-100': index === selectedIndex }"
                           :src="category.image" class="opacity-75" height="26" width="26"/>
            <div :class="index === selectedIndex ? 'text-black font-niceone-bold' : 'text-primary'"
                 class="text-sm flex-1 font-niceone-medium">
              {{ category.name }}
            </div>
          </div>
        </nuxt-link>
      </div>
      <div class="col-span-12 md:col-span-5 py-3">
        <sub-items :sub-categories="subCategories"/>
      </div>
      <menu-types :selected-slug="selectedSlug"/>
    </div>
  </div>
</template>

<script setup>
import {useCategoryStore} from "@/store/category";
import {track} from "@/composables/useTrackEvent";
import {TRACKERS} from "@/constants/events";
import MenuTypes from "@/components/common/dynamic-menu-components/index";
import SubItems from "@/components/common/menuitems/SubItems";
import {useIsEmpty} from "@/composables/useIsEmpty";

const {isEmpty} = useIsEmpty()
const categoryStore = useCategoryStore();
const {getCategories} = storeToRefs(categoryStore)
const selectedIndex = ref(0);
const selectedSlug = ref(getCategories?.value[selectedIndex.value] ? getCategories?.value[selectedIndex.value].seo_name : '');

const selectedCategory = computed(() => getCategories?.value[selectedIndex.value] || [])

const subCategories = computed(() => selectedCategory.value?.categories ?? []);

const hoverMainCategory = (index, category) => {
  selectedIndex.value = index
  selectedSlug.value = category?.seo_name
};

const onMenuClick = (category) => {
  track(TRACKERS.HAM_BURGER_MENU, {
    eventLabel: category.name,
    eventAction: category.seo_url
  })
}
</script>