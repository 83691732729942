import {COOKIE_KEYS} from "~/constants/cookie";
import {CURRENCY_CODES} from "~/helper";

export const useCustomFetch = async (url, params = null) => {

    const {$axios} = useNuxtApp()

    const {headers, baseURL} = $axios.defaults

    const {locale} = useI18n()

    const currency = useCookie(COOKIE_KEYS.CURRENCY)?.value || CURRENCY_CODES.SAR

    const {data, pending} = await useLazyFetch(`${baseURL}/${url}`, {
        params,
        headers: {
            ...headers,
            "x-oc-currency": currency,
            "x-oc-merchant-language": locale.value,
        },
        transform: (data) => JSON.parse(data)?.data
    })

    return {data, loading: pending}
}