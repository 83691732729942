<template>
  <van-popup
      v-model:show="model"
      :position="$i18n.locale === 'en' ? 'right' : 'left'"
      :style="{ width: !$device.isDesktop ? '100%' : '500px', height: '100%' }"
      @closed="onClose"
  >
    <simple-wrapper>
      <template #header>
        <van-nav-bar class="!bg-light-grey" title="" @click-left="onClose">
          <template #left>
            <van-icon name="cross" size="28"/>
          </template>
        </van-nav-bar>
      </template>


      <auth v-if="model"/>


    </simple-wrapper>
  </van-popup>

</template>

<script setup>
import Auth from "@/components/auth";
import {useEventBus} from "@vueuse/core";
import {EVENTS} from "@/constants/events";
import {useAuthStore} from "@/store/auth";


const model = ref(false)

const authStore = useAuthStore()
const {blockRedirection} = storeToRefs(authStore)

const router = useRouter()
const route = useRoute()
onMounted(() => {
  // Open auth sidebar from middleware with the help of auth query params
  if (route.query?.auth) {
    router.replace(useNuxtApp().$routerUrl('/'))
    model.value = true
  }
})

const {on} = useEventBus(EVENTS.AUTH_DIALOG)

on(({show = true, redirection = true} = '') => {
  model.value = show
  blockRedirection.value = !redirection
});


const {emit: emitTermsDialog} = useEventBus(EVENTS.TERMS_DIALOG);
const onClose = () => {
  model.value = false
  blockRedirection.value = false
  emitTermsDialog({dialog: false})
}
</script>