export const EVENTS = {
    VERIFY_DIALOG: 'verify:dialog',
    ADDRESS_SIDEBAR: 'address-sidebar',
    AUTH_DIALOG: 'auth:dialog',
    LIKE_PRODUCTS: 'like-products',
    LOYALTY_REDEEM: 'loyalty-redeem',
    TERMS_DIALOG: 'terms:dialog',
    DELETE_DIALOG: 'delete:dialog',
    CLOSE_FEEDBACK_POPUP: 'feedback:dialog',
    SHARE_ON: 'share-on:dialog',
    WALLET_ON: 'wallet-on:dialog',
    STC_WALLET: 'stc-wallet-on:dialog',
    CART_UPDATED: 'cart:updated'
}

export const TRACKERS = {
    INITIALIZED: 'initialized',
    LOGIN: 'login',
    LOGOUT: 'logout',
    PRODUCT_DETAILS_VIEWED: 'productDetailsViewed',
    PRODUCT_CLICKED: 'productClicked',
    ADD_TO_CART: 'addToCart',
    INCREMENT_CART: 'incrementCart',
    REMOVE_FROM_CART: 'removeFromCart',
    BEGIN_CHECKOUT: 'beginCheckout',
    Add_SHIPPING_INFO: 'addShippingInfo',
    ORDER_SUCCESS: 'orderSuccess',
    ORDER_CANCELLED: 'orderCanceled',
    BANNER_CLICK: 'bannerClick',
    LOGO_CLICK: 'logoClick',
    SEARCH_INITATE: 'searchInitate',
    SEARCH_TERM: 'searchTerm',
    SEARCH_PRODUCT: 'searchProduct',
    NAVIGATION_MENU: 'navigationMenu',
    HAM_BURGER_MENU: 'hamBurgerMenu',
    CTA_CLICK: 'ctaClick',
    //SCROLL_TRACKING: 'scrollTracking',
    CAROUSAL_CLICK: 'carousalClick',
    TOP_PRODUCT_ARROW: 'TopProductArrow',
    HELP_SECTION: 'helpSection',
    BOTTOM_NAVIGATION: 'bottomNavigation',
    BRAND_SELECT: 'brandSelect',
    LOGIN_INITATE: 'loginInitate',
    REGISTER_INITIATE: 'registerInitiate',
    LOGIN_SUCCESS: 'loginSuccess',
    LOGIN_FAIL: 'loginFail',
    FORGOT_PASSWORD: 'forgotPassword',
    REGISTRRATION_SUCCESS: 'registerationsSuccess',
    REGISTRATION_FAIL: 'registrationFail',
    MY_ACCOUNT: 'myAccount',
    PLP_INTERACTION: 'plpInteraction',
    FILTER_INTERACTIONS: 'filterInteractions',
    PRODUCT_SORTING: 'productSorting',
    MORE_PRODUCTS: 'moreProducts',
    FAVORITE_ADD: 'favoriteAdd',
    FAVORITE_REMOVE: 'favoriteRemove',
    PDP_MORE_PRODUCT_DETAILS: 'pdpMoreProductDetails',
    PRODUCT_RATING: 'productRating',
    REVIEW_SORT: 'reviewSort',
    REVIEW_LIKE_COMMENT: 'reviewLikeComment',
    CHECKOUT_OFFERS: 'checkoutOffers',
    CHECKOUT_3_PAYMENT: 'checkout3Payment',
    CHECKOUT_2B_NEW_ADDRESS: 'checkout2bNewAddress',
    CHECKOUT_2A_SET_LOCATION: 'checkout2aSetLocation',
    RECOMMENDED_PRODUCT: 'recommendedProduct',
    CHECKOUT_1_SHOPPING_BAG: 'recommended_product',
    SEARCH_FOR_SUGGESTIONS: 'searchForSuggestions',
    SEARCH: 'search',
    OPEN_PRODUCT_LIST: 'openProductList',
    OPEN_OTP_VERIFICATION_PAGE: 'openOtpVerificationPage',
    SUBMIT_OTP_CODE: 'submitOtpCode',
    OTP_SUCCESS: 'OtpSuccess',
    OPEN_WISHLIST_PAGE: 'openWishlistPage',
    OPEN_NOTIFICATION_CENTER_PAGE: 'openNotificationCenterPage',
    OPEN_ORDER_DERAILS_PAGE: 'openOrderDetailPage',
    OPEN_ORDER_LIST_PAGE: 'openOrderListPage',
    OPEN_TICKET_LIST_PAGE: 'openTicketListPage',
    OPEN_TICKET_PAGE_FROM_TICKETS_LIST: 'openTicketPageFromTicketList',
    OPEN_TICKET_PAGE_FROM_NOTIFICATIONS: 'openTicketPageFromNotifications',
    OPEN_IN_APP: 'openInApp',
    PLAY_ITEM_VIDEO: 'playItemVideo',
    EDIT_ADDRESS: 'editAddress',
    DELETE_ADDRESS: 'deleteAddress',
    CHANGE_SHIPMENT_ADDRESS: 'changeShipmentAddress',
    CHANGE_PAYMENT_METHOD: 'changePaymentMethod',
    OPEN_CHECKOUT_PAGE: 'openCheckoutPage',
    VIEW_HOME_TAB: 'ViewHomeTab',
    OPEN_APP_FROM_SMART_BANNER: 'openAppFromSmartBanner',
    ALGOLIA_PRODUCT_CLICK: 'algoliaProductClick',
    ALGOLIA_ADD_TO_CART: 'algoliaAddToCart',
    CLOSE_PRODUCT_POPUP_REVIEW: 'closeProductPopupReview',
    OPEN_PRODUCT_POPUP_REVIEW: 'openProductPopupReview',
    ITEM_REVIEWED: 'itemReviewed',
    GIFTING_VOUCHER: 'giftVoucher',
    OPEN_REDEEM_POPUP:'openRedemptionPopup',
    OPEN_lOYALTY_PAGE:'openLoyaltyPage',
    CANCEL_REDEMPTION:'cancelRedemption',
    REDEEM_LOYALTY_POINTS:'redeemLoyaltyPoints',
    OPEN_REWARDS_PROGRAM_PAGE:'openRewardsProgramPage',
    OPEN_POPUP_FOR_CASH_OUT_REWARDS:'openPopupForCashOutRewards',
    SHARE_LINK_OF_REWARDS:'shareLinkOfRewards',
    OPEN_HOW_REWARDS_WORKS_PAGE:'openHowRewardsWorksPage',
    COPY_CODE_OF_REWARDS:'copyCodeOfRewards',
    OPEN_VIDEO_PAGE: 'openVideoPage',
    SHARE_REWARD_BANNER: 'shareRewardsBanner',
    CLICK_REWARDS_BANNER: 'clickRewardsBanner',
    OPEN_ALL_REWARDS_OFFERS_PAGE: 'openAllRewardsOffersPage',
    OPEN_BUNDLE_LIST: 'openBundleList',
}
