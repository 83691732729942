<template>
  <div :style="getDynamicStyle" class="rounded-full h-4 flex items-center px-2 whitespace-nowrap">
    <span class="text-[10px]">{{ getText }}</span>
  </div>
</template>

<script setup>

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    }
  },
})

const outOfStock = computed(() => !props?.product?.quantity)
const isNew = computed(() => props.product?.is_new)

const {t} = useI18n()
const getText = computed(() => {
  if (outOfStock.value) return t('product.out_of_stock')
  if (isNew.value) return t("product_tags.new")
  return props.product?.tag?.text || props.product?.special?.[0]?.tag_name || ''
})

const getDynamicStyle = computed(() => {
  if (outOfStock.value) return {background: '#e8e8e8', color: '#222'}
  if (isNew.value) return {background: '#e35c02', color: '#fff'}
  return {
    background: props.product?.tag?.background_color || (props.product?.special?.[0]?.tag_color ? `#${props.product?.special?.[0]?.tag_color}` : null),
    color: '#fff'
  }
})

</script>