<template>
  <div class="col-span-12 md:col-span-5 grid grid-cols-1 gap-3 p-3">
    <Component :is="activeComponent" :data="getComponentData"/>
  </div>
</template>

<script setup>
import {BANNERS_BY_MENU_TYPES, MENU_TYPES} from "@/constants/menu"
import LargeBanners from "@/components/common/dynamic-menu-components/LargeBanners"
import SmallBannersWithBrands from "@/components/common/dynamic-menu-components/SmallBannersWithBrands"
import VerticalBannerWithBrands from "@/components/common/dynamic-menu-components/VerticalBannerWithBrands.vue"

const props = defineProps({
  selectedSlug: {
    type: String,
    default: ""
  }
})

const components = {
  [MENU_TYPES.MAKEUP]: SmallBannersWithBrands,
  [MENU_TYPES.PERFUME]: VerticalBannerWithBrands,
  [MENU_TYPES.CARE]: SmallBannersWithBrands,
  [MENU_TYPES.LENSES]: LargeBanners,
  [MENU_TYPES.DEVICES]: VerticalBannerWithBrands,
  [MENU_TYPES.PREMIUM]: SmallBannersWithBrands,
  [MENU_TYPES.NAILS]: LargeBanners,
  [MENU_TYPES.GIFTS]: LargeBanners,
  [MENU_TYPES.HOME_SCENTS]: LargeBanners,
  [MENU_TYPES.VITAMINS]: LargeBanners,
}
const activeComponent = computed(() => components[props.selectedSlug])
const getComponentData = computed(() => BANNERS_BY_MENU_TYPES[props.selectedSlug])
</script>