<template>
  <div class="h-full">
    <Component :is="activeComponent"/>
    <terms-dialog/>
  </div>
</template>

<script setup>
import { AUTH_TYPES } from "@/constants"
import { useAuthStore } from '@/store/auth'
import LoginWithPhone from "@/components/auth/LoginWithPhone";
import LoginWithEmail from "@/components/auth/LoginWithEmail";
import Register from "@/components/auth/Register";
import Forgot from "@/components/auth/Forgot";
import TermsDialog from "@/components/auth/TermsDialog";

const authStore = useAuthStore()
const { selectedType } = storeToRefs(authStore)

const components = {
    [AUTH_TYPES.LOGIN_PHONE]: LoginWithPhone,
    [AUTH_TYPES.LOGIN_EMAIL]: LoginWithEmail,
    [AUTH_TYPES.REGISTER]: Register,
    [AUTH_TYPES.FORGOT]: Forgot
}
const activeComponent = computed(() => components[selectedType.value])
</script>